import React from 'react';
import { object, string } from 'prop-types';
import WidgetHeader from '../WidgetHeader';
import { getThemeClassName } from '@/components/widgets/widgetHelpers';

/**
 * @function Embed
 * @description provides an Embed widget
 * @param {String} [props.header]
 * @param {Object} [props.headerImage]
 * @param {String} [props.pageTheme]
 * @returns {React.ReactElement}
 */
const Embed = ({ embedHtml, header, headerImage, pageTheme, ...props }) => (
	<section
		className={`widget embed-widget ${getThemeClassName(pageTheme)}`}
		data-hook="embed-widget"
	>
		{header && <WidgetHeader title={header} thumbnail={headerImage} />}
		<div
			className="embed-widget__body"
			data-hook="embed-body"
			dangerouslySetInnerHTML={{
				__html: embedHtml
			}}
		/>
	</section>
);

Embed.propTypes = {
	embedHtml: string,
	header: string,
	headerImage: object,
	pageTheme: string
};

Embed.displayName = 'Embed';

export default Embed;
